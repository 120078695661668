<template>
  <div v-if="stage">
    <div v-if="stage.isEnded">Формирование команд завершено.</div>
    <div v-else-if="endDate">Формирование команд завершится {{ endDate }}</div>
    <div v-else-if="startDate">
      Формирование команд начнется с {{ startDate }}
    </div>
    <div v-else>Формирование команд еще не началось.</div>
  </div>
  <div v-else>Этап формирования команд уточняется.</div>
</template>

<script>
import { DATE_FROMAT_WITH_UTC } from "@/constants";
import dayjs from "@/plugins/dayjs";
export default {
  name: "TeamBuildingStageText",
  props: {
    stage: {
      type: Object,
    },
  },
  computed: {
    endDate() {
      const { stage } = this;
      if (stage.isActive && stage.end_at) {
        return dayjs(stage.end_at).format(DATE_FROMAT_WITH_UTC);
      }
      return "";
    },
    startDate() {
      const { stage } = this;
      if (!stage.isActive && stage.start_at) {
        return dayjs(stage.start_at).format(DATE_FROMAT_WITH_UTC);
      }
      return "";
    },
  },
};
</script>

<style></style>
