<template>
  <div>
    <StageStatusPanel class="mb-xl" />

    <section class="mb-l">
      <div v-if="profileList.length">
        <div class="row cards-list">
          <div
            v-for="profile in profileList"
            :key="profile.id"
            class="col-xs-12 cards-list__item">
            <div class="stage-profile cards-list__card">
              <div class="stage-profile__icon">
                <BaseImage
                  v-if="profile.icon"
                  :src="profile.icon" />
                <BaseImage
                  v-else
                  src="@/assets/images/profile_placeholder.svg" />
                <!-- <BaseIcon glyph="brand" class="p-card__icon-placeholder" /> -->
              </div>
              <div class="stage-profile__content">
                <div class="stage-profile__type text-size-sm">
                  {{ profile.profileType }}
                </div>
                <div class="stage-profile__title text-size-h4 text-bold">
                  {{ profile.title }}
                </div>
                <div class="stage-profile__row">
                  <div class="stage-profile__col">
                    <div v-if="!profile.hideTeamInfo">
                      <!-- Если нет команды -->
                      <p v-if="!profile.team">
                        Вам нужно собрать команду: создать свою или
                        присоединиться к&nbsp;существующей. Без команды
                        вы&nbsp;не&nbsp;сможете решать командные задачи.
                      </p>
                      <div v-else>
                        <div v-if="teams[profile.id]">
                          Команда: {{ teams[profile.id].title }}
                        </div>
                        <div v-else>{{ profile.team }}</div>
                      </div>
                      <TeamBuildingStageText
                        class="mt-xs"
                        :stage="profile.buildingStage" />
                    </div>
                  </div>
                  <div class="stage-profile__col stage-profile__col--controls">
                    <template v-if="!profile.team && profile.allowTeamBuilding">
                      <BaseButton
                        theme="primary-border"
                        class="mb-xs"
                        @click="openEditTeamModal(profile.id)"
                        >Создать команду</BaseButton
                      >
                      <BaseButton
                        theme="primary-border"
                        @click="openFindTeamModal(profile.id)"
                        >Найти команду</BaseButton
                      >
                    </template>
                    <BaseButton
                      v-else
                      theme="primary-border"
                      class="mb-xs"
                      tag="router-link"
                      :to="{
                        name: `user-profile-stage-${stageNumber}`,
                        params: {
                          profile_id: profile.id,
                        },
                      }"
                      >Подробнее</BaseButton
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p v-else>Нет профилей и&nbsp;спецпроектов командного этапа</p>
    </section>

    <EducationBlocks
      v-if="educationBlocks.length && profileList.length"
      :blocks="educationBlocks"
      class="mb-l mt-l" />

    <section v-if="profileList.length">
      <h4 class="text-size-h4 text-bold color-secondary mb-s">Задания</h4>
      <div v-if="allowedActivities.length">
        <ActivityCard
          v-for="item in allowedActivities"
          :key="item.id"
          :activity="item"
          class="mb-xs"
          :score="
            activityScores ? activityScores[item.id] : null
          "></ActivityCard>
      </div>
      <div v-else>Расписание уточняется, задания появятся позже.</div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ActivityCard from "@/components/profile/ActivityCard";
import { HIDE_TEAMS_PROPFILE, TEAM_STAGE } from "@/constants";
import TeamEditModal from "@/components/user/modals/TeamEditModal";
import TeamFindModal from "@/components/user/modals/TeamFindModal";
import TeamBuildingStageText from "@/components/user/TeamBuildingStageText";
import StageStatusPanel from "@/components/user/StageStatusPanel";
import EducationBlocks from "@/components/education/EducationBlocks.vue";

export default {
  name: "StageTeam",
  components: {
    ActivityCard,
    TeamBuildingStageText,
    StageStatusPanel,
    EducationBlocks,
  },
  props: {
    stageNumber: {
      type: Number,
    },
    activities: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapState({
      profiles: (state) => state.profile.profiles,
      teams: (state) => state.participant.teams,
    }),
    ...mapGetters({
      teamStageList: "participant/teamStageList",
      user: "user/user",
      activityScores: "participant/activityScores",
    }),

    profileList() {
      const { teamStageList, profiles } = this;
      return teamStageList.reduce((acc, value) => {
        const p = profiles[value.profile_id];
        if (p) {
          const profileType = p.is_special_project ? "Спецпроект" : "Профиль";
          acc.push({
            ...p,
            profileType,
            team: value.team_id,
            allowTeamBuilding: value.allowTeamBuilding,
            buildingStage: value.buildingStage,
            hideTeamInfo: HIDE_TEAMS_PROPFILE.includes(value.profile_id),
          });
        }
        return acc;
      }, []);
    },

    educationBlocks() {
      const list = this.$store.state.participant.edublocks;
      const profiles =
        this.$store.getters["participant/teamStageList"]?.map(
          (n) => n.profile_id
        ) || [];
      if (!list?.length) return [];
      return list.filter((n) => {
        return n.profiles && n.profiles.some((id) => profiles.includes(id));
      });
    },

    allowedActivities() {
      const { activities } = this;
      if (!activities?.length) return [];
      return activities;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getTeams();
    },
    openEditTeamModal(profile) {
      const stage = this.profiles[profile]?.steps?.find(
        (n) => n.stage === TEAM_STAGE
      );
      /**
       * Если не указано id мероприятия
       * то покажем ошибку
       */
      if (!stage?.talent_event_id) {
        this.showErrorModal({
          content: `У профиля ${
            profile.title || profile.id
          } не указано мероприятие командного этапа. Пожалуйста, сообщите нам об этой ошибке.`,
          report: true,
        });
      }
      this.$modal.show(
        TeamEditModal,
        {
          profileId: profile,
          eventId: stage.talent_event_id,
        },
        {
          adaptive: true,
          scrollable: true,
          minHeight: 200,
          height: "auto",
        }
      );
    },
    openFindTeamModal(profile) {
      this.$modal.show(
        TeamFindModal,
        {
          profileId: profile,
          eventId: 1,
        },
        {
          adaptive: true,
          scrollable: true,
          minHeight: 200,
          height: "auto",
        }
      );
    },
    async getTeams() {
      const { profileList } = this;
      if (!profileList?.length) return;
      const teamRequests = [];
      const scheduleRequests = [];
      profileList.forEach((n) => {
        teamRequests.push(this.$store.dispatch("participant/getTeam", n.id));
        scheduleRequests.push(
          this.$store.dispatch("profile/getProfileSchedule", n.id)
        );
      });
      try {
        teamRequests.length && (await Promise.all(teamRequests));
      } catch (error) {
        this.showErrorModal({
          title: "Не удалось получить информацию о команде",
          message: error.message,
        });
      }

      try {
        scheduleRequests.length && (await Promise.all(scheduleRequests));
      } catch (error) {
        this.showErrorModal({
          title: "Не удалось получить расписание профиля",
          message: error.message,
          report: true,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.stage-profile {
  background-color: #fff;
  display: flex;
  flex-flow: row nowrap;
  border-radius: @radius-s;
  position: relative;
  padding: 18px 24px;
  box-shadow: @shadow-default;
  transition: box-shadow 0.3s;
  color: @secondary-color;
  .fz(14);

  .media-min-sm({
    display: block;
  });

  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 14px;

    .media-min-sm({
      margin-bottom: 5px;
      margin-right: 0;
    });
  }

  &__content {
    min-width: 0;
    flex-grow: 1;
  }

  &:hover {
    box-shadow: @shadow-hover;
  }

  &__title {
    color: @base-color;
  }

  &__row {
    display: flex;
    flex-flow: row nowrap;
    max-width: 100%;
    min-width: 0;
    margin-top: 14px;
    align-items: center;

    .media-min-sm({
      display: block;
      width: 100%;
    });
  }

  &__col {
    flex-grow: 1;
    min-width: 0;
    overflow: hidden;
    min-width: 50%;
    min-width: min-content;

    &--controls {
      flex-grow: 0;
      white-space: nowrap;
      align-self: flex-start;

      ::v-deep .base-btn {
        min-width: 160px;
        margin-left: 14px;
      }

      .media-min-sm({
        white-space: normal;
        margin-top: 20px;

        ::v-deep .base-btn {
          min-width: 160px;
          margin-left: 0px;
        }
      });
    }
  }
}
</style>
