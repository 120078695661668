<template>
  <div class="team-find">
    <BaseModalContent
      :dynamic="true"
      @close="$emit('close')">
      <h2 class="text-bold text-size-h2 mb-s">Найти команду</h2>
      <div class="mb-l">
        <p>
          Есть несколько способов найти подходящую команду и&nbsp;присоединиться
          к&nbsp;ней.
        </p>
        <p
          v-if="isHighSchool"
          class="mt-m">
          Помните, что вы можете объединиться в команду только с участниками
          того же трека, что и вы.
          <span class="text-bold">{{ participantTrack }}</span>
        </p>
      </div>
      <div class="typography">
        <section class="mb-l">
          <h4 class="text-size-h4 mb-s text-bold">
            Объединиться со знакомыми участниками
          </h4>
          <div>
            Если вы&nbsp;уже знаете, с&nbsp;кем хотите объединиться, и&nbsp;эти
            участники создали команду, попросите капитана пригласить вас
            в&nbsp;нее.
          </div>
        </section>
        <section class="mb-l">
          <h4 class="text-size-h4 mb-s text-bold">В чате или группе профиля</h4>
          <div v-if="chatLinks && chatLinks.length">
            <p>
              Напишите объявление, что вам нужна команда. В&nbsp;объявлении
              расскажите, в&nbsp;каком классе вы&nbsp;учитесь, откуда
              вы&nbsp;и&nbsp;немного о&nbsp;своих компетенциях, знаниях
              и&nbsp;интересах. Не&nbsp;забудьте добавить, как с&nbsp;вами
              связаться, если не&nbsp;хотите общаться с&nbsp;потенциальными
              сокомандниками в&nbsp;общем пространстве.
            </p>
            <p>Объявление готово? Тогда перейдите по ссылке и запостите его!</p>
            <ul v-if="chatLinks.length">
              <li
                v-for="item in chatLinks"
                :key="item.id">
                <a
                  :href="item.url"
                  class="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ item.title }}</a
                >
              </li>
            </ul>
          </div>
          <div v-else>
            У&nbsp;этого профиля еще нет чата. Как только он&nbsp;появится,
            мы&nbsp;добавим ссылку. А&nbsp;пока вы&nbsp;можете подготовить
            объявление о&nbsp;поиске команды: в&nbsp;каком классе
            вы&nbsp;учитесь, чем интересуетесь, какой у&nbsp;вас опыт
            и&nbsp;компетенции, удобные способы связи.
          </div>
        </section>
        <section
          v-if="false"
          class="mb-l">
          <h4 class="text-size-h4 mb-s text-bold">С помощью чат-бота</h4>
          <div v-if="!$store.state.allowTelegram">
            <p>
              Вскоре мы&nbsp;запустим специального бота на&nbsp;платформе
              Telegram, который поможет вам найти команду. Чтобы
              им&nbsp;воспользоваться, привяжите свой аккаунт Telegram
              к&nbsp;Личному кабинету.
            </p>
            <div class="mt-s">
              <BaseButton
                v-if="!hasTelegram"
                tag="a"
                :href="connectTelegramUrl"
                class="mt-m mr-m"
                theme="primary-border"
                target="_blank"
                rel="noopener noreferrer"
                >Привязать телеграм</BaseButton
              >
              <div
                v-else
                class="color-success">
                <BaseIcon glyph="done" />&nbsp;Аккаунт привязан
              </div>
            </div>
          </div>
          <div v-else>
            <div class="typography">
              <p>
                Мы&nbsp;запустили специального бота на&nbsp;платформе Telegram,
                который поможет вам найти команду. Чтобы
                им&nbsp;воспользоваться, привяжите свой аккаунт Telegram
                к&nbsp;Личному кабинету. Затем перейдите к&nbsp;диалогу
                с&nbsp;ботом и&nbsp;активируйте его.
              </p>

              <p class="text-bold">
                Скринкасты по командообразованию через Telegram бота
              </p>
              <ul>
                <li
                  v-for="(chat, idx) in $store.state.tgBotVideo"
                  :key="idx">
                  <a
                    :href="chat.url"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="link"
                    >{{ chat.title }}</a
                  >
                </li>
              </ul>
            </div>
            <BaseButton
              v-if="!hasTelegram"
              tag="a"
              :href="connectTelegramUrl"
              class="mt-m mr-m"
              theme="primary-border"
              target="_blank"
              rel="noopener noreferrer"
              >Привязать телеграм</BaseButton
            >
            <BaseButton
              v-if="botLink"
              class="mt-m"
              :disabled="!hasTelegram"
              theme="primary-border"
              tag="a"
              :href="botLink"
              target="_blank"
              rel="noopener noreferrer"
              >Перейти к боту</BaseButton
            >
          </div>
        </section>
        <section
          v-if="!isJunior"
          class="mb-l">
          <h4 class="text-size-h4 mb-s text-bold">Публичное портфолио</h4>
          <div>
            Откройте свое портфолио и&nbsp;поделитесь ссылкой на&nbsp;него
            в&nbsp;чате профиля. Так другие участники сразу увидят, какие
            достижения у&nbsp;вас уже есть. Настроить и&nbsp;опубликовать
            портфолио можно в&nbsp;личном кабинете на&nbsp;платформе
            &laquo;Талант&raquo;.
            <a
              href="#"
              class="link"
              ><strong>личном кабинете</strong></a
            >&nbsp;на платформе Талант.
          </div>
        </section>
        <BaseButton
          theme="primary"
          @click.prevent="$emit('close')"
          >Закрыть</BaseButton
        >
      </div>
    </BaseModalContent>
  </div>
</template>

<script>
/**
 * Модалка с инфой о том как найти команду
 */
import { mapGetters } from "vuex";
export default {
  name: "TeamFindmodal",
  props: {
    profileId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isJunior: "participant/isJunior",
      isHighSchool: "participant/isHighSchool",
    }),
    profile() {
      return this.$store.state.profile.profiles[this.profileId];
    },
    chatLinks() {
      const { profile } = this;
      if (!profile) return;
      return profile.chats?.filter((n) => n.teamlink);
    },
    hasTelegram() {
      const accounts = this.$store.state.user.telegramAccounts;
      return !!accounts?.length;
    },
    botLink() {
      const name = process.env.VUE_APP_TELEGRAM_BOT_NAME;
      if (name) {
        return `https://t.me/${name}`;
      }
      return undefined;
    },
    connectTelegramUrl() {
      return `${process.env.VUE_APP_TALENT_BASE_URL}/profile/telegram`;
    },
    participantTrack() {
      const tracks = this.$store.state.tracks;
      const id =
        this.$store.getters["participant/teamProfileTracks"][this.profileId] ||
        this.$store.state.participant.track.id;

      const track = tracks[id];
      if (track) return track.title;
      return undefined;
    },
  },
};
</script>
