<template>
  <div class="ci-form">
    <BaseInput
      class="ci-form__input"
      :value="value"
      readonly />
    <BaseButton
      class="ci-form__btn"
      type="button"
      :disabled="!value"
      @click="handleCopy"
      ><DoneText
        :text="textAfter"
        :done="isСopied"
        >{{ textBefore }}</DoneText
      >
    </BaseButton>
  </div>
</template>

<script>
import { copyToClipboard, delay } from "@/utils";
import DoneText from "@/components/DoneText.vue";

export default {
  name: "CopyInput",
  components: {
    DoneText,
  },
  props: {
    value: {
      required: true,
      type: [String, Number],
    },
    textBefore: {
      type: String,
      default: "Копировать ссылку",
    },
    textAfter: {
      type: String,
      default: "Готово",
    },
    delay: {
      type: Number,
      default: 1500,
    },
  },
  data() {
    return {
      isСopied: false,
    };
  },
  methods: {
    async handleCopy() {
      copyToClipboard(this.value);
      this.isСopied = true;
      this.$emit("onCopy", this.value);
      await delay(this.delay);
      this.isСopied = false;
    },
  },
};
</script>

<style lang="less" scoped>
.ci-form {
  display: flex;
  flex-flow: row nowrap;

  &__btn {
    flex-grow: 0;
    flex-shrink: 0;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  &__input {
    flex-grow: 1;
    border-top-right-radius: 0 !important;

    & ::v-deep .base-input__control {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}
</style>
