<template>
  <div>
    <StageStatusPanel class="mb-xl" />

    <section class="mb-l">
      <div v-if="profileList.length">
        <div class="row cards-list">
          <div
            v-for="profile in profileList"
            :key="profile.id"
            class="col-sm-6 col-xs-12 cards-list__item">
            <SmallProfileCard
              class="cards-list__card"
              :title="profile.title"
              :icon="profile.icon"
              :profile-type="profile.profileType"
              :link-to="{
                name: 'user-profile-stage-1',
                params: { profile_id: profile.id },
              }">
              <div
                v-if="profile.scores && profile.scores.length"
                class="prof-scores">
                <ScoreInfo
                  v-for="(item, idx) in profile.scores"
                  :key="idx"
                  :score="item.score"
                  :track="item.track"
                  :updated="item.updated_at"
                  class="mb-xs" />
              </div>
              <div
                v-else
                class="color-meta text-size-s mt-s">
                Нет данных по набранным баллам
              </div>
            </SmallProfileCard>
          </div>
        </div>
      </div>
      <div v-else>
        <p v-if="isJunior">
          Выбери сферу, в которой планируешь участвовать
          <router-link
            :to="{ name: 'user-profiles' }"
            class="link"
            >Выбрать сферу</router-link
          >
        </p>
        <p v-else>
          Выбери профили и&nbsp;спецпроекты в&nbsp;которых ты&nbsp;будешь
          участвовать
          <router-link
            :to="{ name: 'user-profiles' }"
            class="link"
            >Выбрать профили</router-link
          >
        </p>
      </div>
    </section>
    <EducationBlocks
      v-if="educationBlocks.length"
      :blocks="educationBlocks"
      class="mb-l mt-l" />
    <section v-if="activities.length">
      <h4 class="text-size-h4 text-bold color-secondary mb-s">
        Задания по выбранным профилям
      </h4>
      <div>
        <ActivityCard
          v-for="item in activities"
          :key="item.id"
          :activity="item"
          class="mb-xs"
          :score="
            activityScores ? activityScores[item.id] : null
          "></ActivityCard>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SmallProfileCard from "@/components/profile/SmallProfileCard";
import ActivityCard from "@/components/profile/ActivityCard";
import ScoreInfo from "@/components/profile/ScoreInfo";
import StageStatusPanel from "@/components/user/StageStatusPanel";
import EducationBlocks from "@/components/education/EducationBlocks.vue";

export default {
  name: "UserStage1",
  components: {
    SmallProfileCard,
    ActivityCard,
    ScoreInfo,
    StageStatusPanel,
    EducationBlocks,
  },
  props: {
    activities: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    ...mapState({
      profiles: (state) => state.profile.profiles,
      scores: (state) => state.participant.scores,
      tracks: (state) => state.tracks,
    }),
    ...mapGetters({
      selectedList: "participant/selectedList",
      user: "user/user",
      activityScores: "participant/activityScores",
      isStudent: "participant/isStudent",
      isJunior: "participant/isJunior",
      labels: "participant/profileLabels",
      individualProfilesScores: "participant/individualProfilesScores",
    }),

    educationBlocks() {
      const list = this.$store.state.participant.edublocks;
      const profiles =
        this.$store.getters["participant/selectedList"]?.map(
          (n) => n.profile_id
        ) || [];
      if (!list?.length) return [];
      return list.filter((n) => {
        return n.profiles && n.profiles.some((id) => profiles.includes(id));
      });
    },

    profileList() {
      const { selectedList, profiles, profileScores, labels } = this;
      return selectedList.reduce((acc, value) => {
        const p = profiles[value.profile_id];
        if (p) {
          const profileType = p.is_special_project
            ? labels.special.name
            : labels.profile.name;
          acc.push({
            ...p,
            profileType,
            scores: profileScores[value.profile_id],
          });
        }
        return acc;
      }, []);
    },
    /**
     * Баллы за профиль индивидуального этапа
     */
    profileScores() {
      return this.individualProfilesScores;
    },
  },
};
</script>

<style lang="less" scoped>
.prof-scores {
  margin-top: 5px;
  font-size: 14px;
}
</style>
