<template>
  <span
    class="done-text"
    :class="{ 'is-active': done }">
    <span class="done-text__base"><slot></slot></span>
    <span class="done-text__done">
      <slot name="done"><BaseIcon glyph="done" />&nbsp;{{ text }}</slot>
    </span>
  </span>
</template>

<script>
export default {
  name: "DoneText",
  props: {
    text: {
      type: String,
      default: "Готово",
    },
    done: {
      type: Boolean,
    },
  },
};
</script>

<style lang="less">
.done-text {
  display: inline-block;
  overflow: hidden;
  position: relative;

  &__base {
    opacity: 1;
    transition: opacity 0.3s;
  }

  &__done {
    width: 100%;
    display: block;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s;
    left: 0;
    top: 0;
    position: absolute;
  }

  &.is-active &__base {
    opacity: 0;
  }

  &.is-active &__done {
    opacity: 1;
  }
}
</style>
