const ONTI_ORIGIN = window.location.origin;
/**
 * Ссылка приглашение в команду
 * @param {string} code
 * @param {string} profile_id
 * @returns {string=} url
 */
export const teamInviteURL = (code, profile_id, team_id) => {
  if (code && profile_id && team_id) {
    return ONTI_ORIGIN + `/invite/team/${profile_id}/${team_id}/${code}`;
  }
};
