<template>
  <div class="team-modal">
    <BaseModalContent
      :name="name"
      :dynamic="true"
      @close="$emit('close')">
      <h2
        v-if="!teamId"
        class="text-bold text-size-h2 mb-s">
        Создать команду
      </h2>
      <h2
        v-else
        class="text-bold text-size-h2 mb-s">
        Редактировать команду
      </h2>
      <!-- Если экран создания и команда не создана -->
      <div
        v-if="!teamCreated && !teamId"
        class="mb-m typography">
        <p>
          Создать команду очень важно! Зовите тех, с&nbsp;кем вам будет
          комфортно и&nbsp;интересно работать! Подумайте, кто готов осваивать
          новые навыки ради победы и&nbsp;нового опыта! Создав команду,
          вы&nbsp;станете ее&nbsp;капитаном и&nbsp;сможете менять состав.
        </p>
        <p v-if="!eventInfoPending">
          <strong>Максимальное количество участников в команде:</strong>
          {{ maxUsers || "?" }}
        </p>
        <p
          v-if="eventInfoError"
          class="form-error">
          {{ eventInfoError }}
        </p>
      </div>

      <!-- Если команда была только что создана -->
      <div v-if="teamCreated">
        <p class="mb-l">
          Ваша команда
          <span class="text-bold">{{ form.title }}</span> создана.<br />Чтобы
          пригласить других участников в&nbsp;команду, скопируйте
          и&nbsp;отправьте им&nbsp;ссылку в&nbsp;мессенджере, соцсети или
          по&nbsp;e-mail.
        </p>

        <div class="team-modal__invite">
          <BaseInput
            id="invite-team-link"
            class="team-modal__invite-input"
            :value="link"
            readonly />

          <BaseButton
            id="invite-team-link-copy"
            class="team-modal__invite-btn"
            type="button"
            :disabled="!link"
            @click="copyInviteLink"
            ><DoneText :done="isСopied">Копировать ссылку</DoneText></BaseButton
          >
        </div>
      </div>
      <BaseLoadingBox
        v-else
        :pending="pending">
        <ValidationObserver
          ref="form"
          tag="form"
          @submit.prevent="handleSubmit">
          <div class="form-row">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required|max:60">
              <label
                for="team_name"
                class="form-label text-bold"
                >Название команды*</label
              >
              <BaseInput
                id="team_name"
                v-model="form.title"
                :errors="errors"
                placeholder="Название команды" />
              <p class="mt-s text-size-s color-meta">
                Название команды видят все.<br />
                Здесь можно придумать что-то креативное, но&nbsp;помните, что
                на&nbsp;НТО запрещены оскорбительные, нецензурные
                и&nbsp;в&nbsp;плохом смысле неоднозначные названия команд.
              </p>
            </ValidationProvider>
          </div>

          <div class="form-row">
            <ValidationProvider
              v-slot="{ errors }"
              rules="max:1000">
              <label
                for="team_description"
                class="form-label text-bold"
                >Описание команды</label
              >
              <BaseInput
                id="team_description"
                v-model="form.description"
                :errors="errors"
                type="textarea"
                rows="6"
                placeholder="" />
              <p class="mt-s text-size-s color-meta">
                Описание команды видят все.<br />
                Расскажите самое важное о&nbsp;своей команде&nbsp;&mdash;
                например, с&nbsp;какой целью вы&nbsp;участвуете в&nbsp;НТО.
                Не&nbsp;используйте нецензурные слова, избегайте оскорблений
                и&nbsp;плохих двусмысленностей
              </p>
            </ValidationProvider>
          </div>

          <div class="form-row">
            <ValidationProvider
              v-slot="{ errors }"
              vid="contact_link"
              name="contact_link"
              :rules="{ url: true, team_chat: true }">
              <label
                for="team_description"
                class="form-label text-bold"
                >Ссылка на чат команды</label
              >
              <BaseInput
                id="team_description"
                v-model="form.contact_link"
                :errors="errors"
                type="url"
                rows="6"
                placeholder="" />
              <p class="mt-s text-size-s color-meta">
                Укажите ссылку на телеграм чат, или чат во Вконтакте. Эту ссылку
                увидят только участники команды. (<span class="no-select"
                  >https://t.me/+... или https://vk.me/join/...</span
                >
                )
              </p>
            </ValidationProvider>
          </div>

          <div
            v-if="error"
            class="form-error mb-m">
            {{ error }}
          </div>
          <BaseButton
            v-if="!teamId"
            id="save-team"
            class="mb-m"
            type="submit"
            >Создать команду</BaseButton
          >
          <BaseButton
            v-else
            id="update-team"
            class="mb-m"
            type="submit"
            >Сохранить изменения</BaseButton
          >&emsp;
          <BaseButton
            type="button"
            theme="primary-border"
            @click.prevent="$emit('close')"
            >Закрыть</BaseButton
          >
        </ValidationObserver>
      </BaseLoadingBox>
    </BaseModalContent>
  </div>
</template>

<script>
import { talentRequest } from "@/services/api";
import { teamInviteURL } from "@/utils/urls";
import { copyToClipboard, delay } from "@/utils";
import DoneText from "@/components/DoneText";
import MemberFindModal from "@/components/user/modals/MemberFindModal";
import { MODAL_DYNAMIC_DEFAULTS } from "@/constants";
/**
 * Модалка для создания или редактирования команды
 */
export default {
  name: "TeamEditModal",
  components: { DoneText },
  props: {
    teamId: {
      type: Number,
    },
    eventId: {
      type: Number,
      required: true,
    },
    profileId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      name: "team-modal",
      pending: false,
      error: "",
      form: {
        title: "",
        description: "",
        contact_link: "",
      },
      teamCreated: false,
      code: null,
      isСopied: false,
      eventInfoError: "",
      eventInfoPending: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    link() {
      const { code, profileId, teamCreated } = this;
      return teamInviteURL(code, profileId, teamCreated);
    },
    maxUsers() {
      return this.$store.state.profile.events[this.eventId]?.max_users;
    },
    userTeam() {
      return this.$store.state.participant.teams[this.profileId];
    },
    currentStage() {
      return this.$store.getters["participant/teamStageList"]?.find(
        (n) => n.profile_id === +this.profileId
      );
    },
  },
  created() {
    if (!this.teamId) {
      this.getEventInfo();
    } else {
      this.getTeam();
    }
  },
  methods: {
    /**
     * Получаем инфу об ивенте,
     * чтобы понять лимит участников в команде
     */
    async getEventInfo() {
      this.eventInfoPending = true;
      try {
        await this.$store.dispatch("profile/getEvents", [this.eventId]);
        if (!this.maxUsers) {
          this.eventInfoError =
            "Максимальный размер команды не указан. Пожалуйста, сообщите нам об этой ошибке.";
        }
      } catch (error) {
        this.eventInfoError = error.message;
      }
      this.eventInfoPending = false;
    },
    async copyInviteLink() {
      copyToClipboard(this.link);
      this.isСopied = true;
      await delay(1500);
      this.isСopied = false;
      this.$emit("close");
    },
    async handleSubmit() {
      const { user, form, teamId } = this;
      if (this.pending) return;
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.pending = true;
      const { contact_link, ...restForm } = form;

      if (contact_link) {
        const isValidLink = await this.validateContactLink(contact_link);
        if (!isValidLink) {
          this.$refs.form.setErrors({
            contact_link: [
              "Укажите ссылку на чат в формате https://t.me/+... или https://vk.me/join/...",
            ],
          });
          this.pending = false;
          return;
        }
      }

      const payload = {
        ...restForm,
        event: this.eventId,
      };

      try {
        // Если создаем команду
        if (!teamId) {
          const { data } = await talentRequest({
            method: "POST",
            url: `/api/users/${user.talent_id}/teams/`,
            data: payload,
          });

          if (contact_link) {
            await talentRequest({
              method: "PATCH",
              url: `/v2/teams/${data.id}`,
              data: {
                contact_link,
              },
            });
          }

          this.$store.commit("participant/UPDATE_PROFILE_STAGE", {
            ...this.currentStage,
            team_id: data?.id,
          });

          await this.$store.dispatch("participant/getTeam", this.profileId);

          this.pending = false;
          this.$emit("close");
          this.$modal.show(
            MemberFindModal,
            {
              profileId: this.profileId,
            },
            MODAL_DYNAMIC_DEFAULTS
          );
          return;
        } else {
          const patchPayload = {
            ...payload,
          };
          if (contact_link) {
            patchPayload.contact_link = contact_link;
          }
          await talentRequest({
            method: "PATCH",
            url: `/v2/teams/${teamId}`,
            data: patchPayload,
          });
          // Обновление в сторе делаю только формы.
          // иначе стригерятся загрузка аватарок для участников команд,
          // Потому что они придут с новыми хешами(
          this.$store.commit("participant/SET_TEAM", {
            ...this.userTeam,
            ...this.form,
            profile_id: this.profileId,
          });
          this.$emit("close");
        }
      } catch (error) {
        console.log("error", error);
        this.error = error.message;
      }
      this.pending = false;
    },
    async validateContactLink(url) {
      try {
        await talentRequest({
          method: "POST",
          url: "/v2/teams/contact-validate",
          headers: {
            "content-type": "application/json",
          },
          data: JSON.stringify(url),
        });
        return true;
      } catch (error) {
        return false;
      }
    },
    async getTeam() {
      const { userTeam } = this;
      if (!userTeam) {
        try {
          await this.$store.dispatch("participant/getTeam", this.profileId);
        } catch (error) {
          console.log("error", error);
        }
      }

      if (userTeam) {
        this.form.title = userTeam.title;
        this.form.description = userTeam.description;
        this.form.contact_link = userTeam.contact_link;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.team-modal {
  &__invite {
    display: flex;
    flex-flow: row nowrap;

    &-btn {
      flex-grow: 0;
      flex-shrink: 0;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    &-input {
      flex-grow: 1;
      border-top-right-radius: 0 !important;

      & ::v-deep .base-input__control {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
}
</style>
